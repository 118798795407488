import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'
import mq from 'styles/breakpoints'

const YouTubeEmbeddedWrapper = styled.div`
  width: 600px;
  height: 338px;

  ${mq['md']} {
    width: 560px;
    height: 315px;
  }

  ${mq['sm']} {
    width: 340px;
    height: 191px;
  }

  ${mq['xsm']} {
    width: 96%;
    height: 54vw;
  }

  iframe {
    width: 100%;
    height: 100%;
  }
`

const YouTubeEmbedded = ({ videoId, title }) => {
  const { t } = useTranslation('youtubeEmbedded')

  return (
    <YouTubeEmbeddedWrapper>
      <iframe
        src={`https://www.youtube.com/embed/${videoId}`}
        title={title || t('title')}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    </YouTubeEmbeddedWrapper>
  )
}

export default YouTubeEmbedded
