export const FLUENTWORLDS_GOOGLE_PLAY_STORE_DOWNLOAD_LINK =
  'https://play.google.com/store/apps/details?id=com.threed_view.FluentWorlds'
export const FLUENTWORLDS_APPLE_APP_STORE_DOWNLOAD_LINK =
  'https://apps.apple.com/us/app/learn-english-fluentworlds-esl/id1047012845'
export const PERFECTACCENT_GOOGLE_PLAY_STORE_DOWNLOAD_LINK =
  'https://play.google.com/store/apps/details?id=com.fluentworlds.accent'
export const PERFECTACCENT_APPLE_APP_STORE_DOWNLOAD_LINK =
  'https://apps.apple.com/us/app/perfectaccent/id1424636159'
export const FLUENTWORLDS_ACADEMY_LINK = 'https://www.fluentworlds.com/academy/'
export const FLUENTWORLDS_WEBINAR_EXTERNAL_LINK =
  'https://ambassadorwebinar.fluentworlds.com/fwambwebinars'
export const FLUENTWORLDS_DEMO_EXTERNAL_LINK =
  'https://calendly.com/ambassadordemo/ambassador-demo'
