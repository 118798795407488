import styled from '@emotion/styled'
import mq from 'styles/breakpoints'
import questionsSectionBackgroundImage from 'images/png/become_ambassador/questions_section/1.png'
import oneOnOneSectionWebinarBackgroundImage from 'images/jpg/become-ambassador/one_on_one_section/webinar.jpg'
import oneOnOneSectionDemoBackgroundImage from 'images/jpg/become-ambassador/one_on_one_section/demo.jpg'

export const JoinSection = styled.section`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  background: #bae6ff;
  margin-bottom: 38px;

  ${mq['lg']} {
    justify-content: space-around;
    align-items: center;
    text-align: center;
  }

  .description-container {
    flex-direction: column;
    justify-content: center;
    width: 30%;
    padding: 46px 40px;
    margin-bottom: 20px;

    ${mq['lg']} {
      width: 100%;
    }

    h1 {
      font-weight: bold;
      font-size: 36px;
      color: #1087ff;
      margin-bottom: 20px;
    }

    p {
      font-size: 14px;
      line-height: 16px;
      margin-bottom: 20px;
    }
  }

  .images-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: start;
    width: 70%;
    max-width: 740px;

    ${mq['lg']} {
      width: 100%;
      justify-content: center;
      display: none;
    }

    .gatsby-image-wrapper {
      height: auto;
      max-width: 100%;
    }

    .gatsby-image-wrapper:nth-of-type(1) {
      width: 122px;
      margin-left: 66px;
      align-self: flex-start;

      ${mq['md']} {
        width: 15.88vw;
        margin-left: 8.6vw;
      }
    }

    .gatsby-image-wrapper:nth-of-type(2) {
      width: 107px;
      margin-left: 42px;
      margin-top: -40px;
      align-self: flex-start;

      ${mq['md']} {
        width: 13.93vw;
        margin-left: 5.47vw;
        margin-top: -5.2vw;
      }
    }

    .gatsby-image-wrapper:nth-of-type(3) {
      width: 123px;
      margin-left: 39px;
      margin-top: 28px;
      align-self: flex-end;

      ${mq['md']} {
        width: 16vw;
        margin-left: 5.07vw;
        margin-top: 3.64vw;
      }
    }

    .gatsby-image-wrapper:nth-of-type(4) {
      width: 70px;
      margin-left: 33px;
      align-self: flex-end;

      ${mq['md']} {
        width: 9.11vw;
        margin-left: 4.29vw;
      }
    }

    .gatsby-image-wrapper:nth-of-type(5) {
      width: 141px;
      margin-top: 30px;

      ${mq['lg']} {
        margin-left: 10px;
      }

      ${mq['md']} {
        width: 18.36vw;
        margin-top: 3.9vw;
        margin-left: 1.3vw;
      }
    }

    .gatsby-image-wrapper:nth-of-type(6) {
      width: 181px;
      margin-left: 30px;
      margin-top: -13px;

      ${mq['md']} {
        width: 23.56vw;
        margin-left: 3.9vw;
        margin-top: -1.69vw;
      }
    }

    .gatsby-image-wrapper:nth-of-type(7) {
      width: 69px;
      margin-left: 20px;

      ${mq['md']} {
        width: 8.98vw;
        margin-left: 2.6vw;
      }
    }

    .gatsby-image-wrapper:nth-of-type(8) {
      width: 133px;
      margin-left: 20px;
      align-self: flex-end;

      ${mq['md']} {
        width: 17.32vw;
        margin-left: 2.6vw;
      }
    }

    .gatsby-image-wrapper:nth-of-type(9) {
      width: 106px;
      margin-left: 123px;
      margin-top: 26px;

      ${mq['md']} {
        width: 13.8vw;
        margin-left: 16.01vw;
        margin-top: 3.38vw;
      }
    }

    .gatsby-image-wrapper:nth-of-type(10) {
      width: 142px;
      margin-left: 26px;
      margin-bottom: -30px;

      ${mq['md']} {
        width: 18.49vw;
        margin-left: 3.38vw;
        margin-bottom: -3.9vw;
      }
    }

    .gatsby-image-wrapper:nth-of-type(11) {
      width: 100px;
      margin-left: 35px;
      margin-top: 18px;
      margin-bottom: -15px;

      ${mq['md']} {
        width: 13.02vw;
        margin-left: 4.56vw;
        margin-top: 2.34vw;
        margin-bottom: -1.95vw;
      }
    }
  }
`

export const OneOnOneSection = styled.section`
  max-width: 860px;
  max-height: 90vh;
  overflow-y: auto;

  ${mq['lg']} {
    max-width: min(600px, 90vw);
  }

  ${mq['sm']} {
    max-width: min(400px, 90vw);
  }

  ${mq['xsm']} {
    max-width: min(300px, 90vw);
  }

  .title {
    font-size: 18px;
    color: #000;
    padding: 18px 22px;
  }

  .options-section {
    display: flex;
    align-items: stretch;
    justify-content: center;

    ${mq['lg']} {
      flex-direction: column;
    }

    .webinar-option,
    .demo-option {
      flex: 1 1 auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      min-width: 357px;
      height: 441px;
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
      text-align: center;
      padding: 12px 28px 32px;

      ${mq['sm']} {
        min-width: 0;
      }

      h2 {
        font-weight: bold;
        font-size: 18px;
        color: white;
      }

      h3 {
        font-weight: normal;
        font-size: 18px;
        color: white;
      }

      .btn {
        font-size: 14px;
        text-transform: uppercase;
      }
    }

    .webinar-option {
      background-image: url(${oneOnOneSectionWebinarBackgroundImage});
    }

    .demo-option {
      background-image: url(${oneOnOneSectionDemoBackgroundImage});
    }

    .text-block {
      flex: 1 1 auto;
      padding: 16px;
      text-align: center;

      .logo {
        img {
          width: 100px;
          height: auto;
        }
      }

      p {
        font-size: 12px;
        color: #000;
        text-align: left;
        margin-top: 8px;
      }
    }
  }
`

export const ConditionSection = styled.section`
  width: 90%;
  margin: 0 auto;

  ${mq['md']} {
    width: 100%;
  }

  h1 {
    font-weight: bold;
    font-size: 30px;
    color: #0085c2;
    margin-bottom: 20px;
    text-align: center;
  }

  .conditions {
    width: 100%;

    .condition-column {
      display: inline-block;
      width: 25%;
      padding: 0 10px;
      margin-bottom: 20px;
      vertical-align: top;

      ${mq['xl']} {
        width: 50%;
      }

      ${mq['sm']} {
        width: 100%;
      }

      .condition-icon {
        width: 102px;
        height: 102px;
        border-radius: 50%;
        margin: 0 auto 18px;
        display: block !important;
      }

      .condition-header {
        font-weight: bold;
        font-size: 16px;
        color: #0ac7ff;
        text-align: center;
      }

      .condition-text {
        font-size: 14px;
        text-align: center;
      }
    }
  }
`

export const ApplySection = styled.section`
  text-align: center;
  margin-bottom: 42px;
`

export const OverviewSection = styled.section`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  margin-bottom: 53px;

  .header {
    font-size: 24px;
    color: #406aff;
    width: 30%;
    margin-top: 80px;
    text-align: center;
    padding-left: 20px;
    padding-right: 20px;

    ${mq['lg']} {
      width: 100%;
      margin-top: 0;
      margin-bottom: 20px;
    }
  }
`

export const HowToApplySection = styled.section`
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: center;
  width: 100%;
  margin-bottom: 18px;

  .photo,
  .description {
    width: 431px;
    margin: 0 13px;
    border-radius: 16px;

    ${mq['lg']} {
      flex-direction: column;
      align-items: center;
    }
  }

  ${mq['lg']} {
    .photo {
      max-width: 96%;
      margin-bottom: 20px;
    }
  }

  .description {
    background: #406aff;
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;

    h1 {
      font-size: 36px;
      margin-bottom: 10px;
      text-align: center;
    }
    h6 {
      font-weight: bold;
      font-size: 14px;
    }
    p {
      font-size: 14px;
      text-align: center;
      margin-bottom: 10px;
    }
  }
`

export const Pill = styled.div`
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background: #0ac7ff;
  line-hegiht: 100%;
  font-size: 30px;
  text-align: center;
  margin: 0 6px 8px;
`

export const SignInSection = styled.section`
  text-align: center;
  margin-bottom: 40px;
  padding: 0 13px;

  p {
    font-size: 18px;
    margin-bottom: 10px;
  }
`

export const QuestionsSection = styled.section`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  width: 100%;
  min-height: 17.75vw;
  padding: 0 20px;
  background-image: url(${questionsSectionBackgroundImage});
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;

  p {
    text-align: center;
    font-size: 18px;
    color: #fff;
    padding: 0 60px;
    margin-bottom: 46px;

    ${mq['md']} {
      margin-top: 20px;
      margin-bottom: 8px;
    }

    a {
      text-decoration: none;
      color: #fff;
    }
  }
`
