import React, { useRef } from 'react'
import styled from '@emotion/styled'

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(77, 77, 77, 0.3);
  z-index: 10;

  .modal {
    border-radius: 16px;
    background-color: white;
    max-width: 90vw;
    overflow: auto;
  }
`

const Modal = ({ children, show = true, onHide = () => {} }) => {
  const overlay = useRef(null)

  const clickHandler = (e) => {
    if (e.target === overlay.current) {
      onHide()
    }
  }

  return (
    <>
      {show && (
        <Wrapper ref={overlay} onClick={clickHandler}>
          <div className="modal">{children}</div>
        </Wrapper>
      )}
    </>
  )
}

export default Modal
