import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { StaticImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'

import Layout from 'components/Layout'
import Seo from 'components/Seo'
import { GradientButton } from 'components/Subscriptions/PricingPlansComponents'
import YouTubeEmbedded from 'components/common/YouTubeEmbedded'
import Modal from 'components/common/Modal'
import {
  FLUENTWORLDS_WEBINAR_EXTERNAL_LINK,
  FLUENTWORLDS_DEMO_EXTERNAL_LINK,
} from 'constants/product-links'
import {
  JoinSection,
  OneOnOneSection,
  ConditionSection,
  ApplySection,
  OverviewSection,
  HowToApplySection,
  Pill,
  SignInSection,
  QuestionsSection,
} from 'components/BecomeAmbassadorComponents'

const BecomeAmbassador = () => {
  const { t } = useTranslation('becomeAmbassador')
  const [showOneOnOneModal, setShowOneOnOne] = useState(false)

  const showOneOnOne = (e) => {
    e.preventDefault()
    setShowOneOnOne(true)
  }

  const howToApplySteps = t('howToApplySteps', {
    returnObjects: true,
  })
  const howToApplyStepsComponents = Array.isArray(howToApplySteps)
    ? howToApplySteps.map((val, index) => {
        return (
          <React.Fragment key={index}>
            <Pill>{index + 1}</Pill>
            <h6>{t(val.header)}</h6>
            <p>{t(val.text)}</p>
          </React.Fragment>
        )
      })
    : howToApplySteps

  return (
    <div>
      <Seo title={t('title')} description={t('description')} />
      <Layout showFooter={false}>
        <main>
          <JoinSection>
            <div className="description-container">
              <h1>{t('joinSectionHeader')}</h1>
              <p>{t('joinSectionText')}</p>
              <GradientButton onClick={showOneOnOne}>
                {t('joinSectionButton')}
              </GradientButton>
              <Modal
                show={showOneOnOneModal}
                onHide={() => {
                  setShowOneOnOne(false)
                }}
              >
                <OneOnOneSection>
                  <div className="title">{t('oneOnOneSectionTitle')}</div>
                  <div className="options-section">
                    <div className="webinar-option">
                      <div>
                        <h2>{t('oneOnOneSectionWebinarHeader')}</h2>
                        <h3>{t('oneOnOneSectionWebinarSubHeader')}</h3>
                      </div>
                      <a
                        href={FLUENTWORLDS_WEBINAR_EXTERNAL_LINK}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <GradientButton className="btn">
                          {t('oneOnOneSectionWebinarButtonLabel')}
                        </GradientButton>
                      </a>
                    </div>
                    <div className="text-block">
                      <Link to="/" className="logo">
                        <StaticImage
                          src="../images/png/fluentworlds_logo.png"
                          alt="fluentworlds.com"
                        />
                      </Link>
                      <p>{t('oneOnOneSectionText')}</p>
                    </div>
                    <div className="demo-option">
                      <div>
                        <h2 className="">{t('oneOnOneSectionDemoHeader')}</h2>
                        <h3 className="">
                          {t('oneOnOneSectionDemoSubHeader')}
                        </h3>
                      </div>
                      <a
                        href={FLUENTWORLDS_DEMO_EXTERNAL_LINK}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <GradientButton className="btn">
                          {t('oneOnOneSectionDemoButtonLabel')}
                        </GradientButton>
                      </a>
                    </div>
                  </div>
                </OneOnOneSection>
              </Modal>
            </div>
            <div className="images-container">
              <StaticImage
                src="../images/png/become_ambassador/join_section/1.png"
                alt=""
              />
              <StaticImage
                src="../images/png/become_ambassador/join_section/2.png"
                alt=""
              />
              <StaticImage
                src="../images/png/become_ambassador/join_section/3.png"
                alt=""
              />
              <StaticImage
                src="../images/png/become_ambassador/join_section/4.png"
                alt=""
              />
              <StaticImage
                src="../images/png/become_ambassador/join_section/5.png"
                alt=""
              />
              <StaticImage
                src="../images/png/become_ambassador/join_section/6.png"
                alt=""
              />
              <StaticImage
                src="../images/png/become_ambassador/join_section/7.png"
                alt=""
              />
              <StaticImage
                src="../images/png/become_ambassador/join_section/8.png"
                alt=""
              />
              <StaticImage
                src="../images/png/become_ambassador/join_section/9.png"
                alt=""
              />
              <StaticImage
                src="../images/png/become_ambassador/join_section/10.png"
                alt=""
              />
              <StaticImage
                src="../images/png/become_ambassador/join_section/11.png"
                alt=""
              />
            </div>
          </JoinSection>
          <ConditionSection>
            <h1>{t('conditionSectionHeader')}</h1>
            <div className="conditions">
              <div className="condition-column">
                <StaticImage
                  className="condition-icon"
                  src="../images/png/become_ambassador/condition_section/1.png"
                  alt=""
                />
                <h6 className="condition-header">
                  {t('conditionFirstColumnHeader')}
                </h6>
                <p className="condition-text">
                  {t('conditionFirstColumnText')}
                </p>
              </div>
              <div className="condition-column">
                <StaticImage
                  className="condition-icon"
                  src="../images/png/become_ambassador/condition_section/2.png"
                  alt=""
                />
                <h6 className="condition-header">
                  {t('conditionSecondColumnHeader')}
                </h6>
                <p className="condition-text">
                  {t('conditionSecondColumnText')}
                </p>
              </div>
              <div className="condition-column">
                <StaticImage
                  className="condition-icon"
                  src="../images/png/become_ambassador/condition_section/3.png"
                  alt=""
                />
                <h6 className="condition-header">
                  {t('conditionThirdColumnHeader')}
                </h6>
                <p className="condition-text">
                  {t('conditionThirdColumnText')}
                </p>
              </div>
              <div className="condition-column">
                <StaticImage
                  className="condition-icon"
                  src="../images/png/become_ambassador/condition_section/4.png"
                  alt=""
                />
                <h6 className="condition-header">
                  {t('conditionForthColumnHeader')}
                </h6>
                <p className="condition-text">
                  {t('conditionForthColumnText')}
                </p>
              </div>
            </div>
          </ConditionSection>
          <ApplySection>
            <a
              href={process.env.GATSBY_AMBASSADOR_SIGNUP_URL}
              target="_blank"
              rel="noreferrer"
            >
              <GradientButton>{t('applyBtnLabel')}</GradientButton>
            </a>
          </ApplySection>
          <OverviewSection>
            <h1 className="header">{t('overviewHeader')}</h1>
            <YouTubeEmbedded videoId="EPTL2o3jmy8" />
          </OverviewSection>
          <HowToApplySection>
            <StaticImage
              className="photo"
              src="../images/png/become_ambassador/how_to_apply_section/1.png"
              alt={t('learningOnline')}
            />
            <div className="description">
              <h1>{t('howToApply')}</h1>
              {howToApplyStepsComponents}
              <ApplySection>
                <a
                  href={process.env.GATSBY_AMBASSADOR_SIGNUP_URL}
                  target="_blank"
                  rel="noreferrer"
                >
                  <GradientButton>{t('applyBtnLabel')}</GradientButton>
                </a>
              </ApplySection>
            </div>
          </HowToApplySection>
          <SignInSection>
            <p>{t('signInSectionText')}</p>
            <a href={process.env.GATSBY_AMBASSADOR_LOGIN_URL}>
              <GradientButton>{t('signInSectionBtnLabel')}</GradientButton>
            </a>
          </SignInSection>
          <QuestionsSection>
            <p>
              {t('questionsSectionText')}
              <br />
              <a href={`mailto:${process.env.GATSBY_AMBASSADOR_CONTACT_EMAIL}`}>
                {process.env.GATSBY_AMBASSADOR_CONTACT_EMAIL}
              </a>
            </p>
          </QuestionsSection>
        </main>
      </Layout>
    </div>
  )
}

export default BecomeAmbassador
